import { setReferringSortSessionData } from 'features/sort';
import { setProductClick } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';
import { utagLinkSelectItem } from 'features/tealium/ga4/ecommerce';

function initTrackProductClick (sortResultContainers) {

	// add GA 'product click' event to sort result products
	const sortResultProducts = document.getElementById('sortResultProducts');
	const listTypeSort = sortResultProducts.getAttribute('data-list-type') ?? 'sort';
	setProductClick(listTypeSort);

	const listType = sortResultProducts && sortResultProducts.getAttribute('data-list-type');
	sortResultContainers.forEach((sortResultContainer) => {

		const clickToProductLink = sortResultContainer.querySelectorAll('.sortResultImgContainer, .sortResultProdName');

		clickToProductLink.forEach((link) => {

			link.addEventListener('click', (e) => {

				setReferringSortSessionData(listType, e.target.closest('.sortResultContainer'));

				// GA4 "select_item" event
				const captureElement = e.target.closest('.sortResultContainer')?.querySelector('.sortResultProdImg');
				if (captureElement) {
					
					utagLinkSelectItem({
						sku: captureElement.dataset.sku,
						name: captureElement.title || captureElement.alt,
						price: captureElement.dataset.price,
						skuInputType: captureElement.dataset.skuInputType,
						primaryCategory: captureElement.dataset.primaryCategory,
						productNewItemDate: captureElement.dataset.productNewItemDate
					});

				}

			});

		});

	});

	// GA4 PseudoSku Click
	const pseudoSkus = document.querySelectorAll('.jsResultContainer.pseudoSku');
	if (pseudoSkus.length) {

		pseudoSkus.forEach((psSku) => {

			psSku.addEventListener('click', (e) => {

				const sku = e.currentTarget.dataset.sku;
				if (sku) {

					setGenericEvent({
						event_name: `sort_pseudosku_click_${sku}`
					});

				}

			});

		});

	}

}

export default initTrackProductClick;
