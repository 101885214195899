import getPathName from 'features/tealium/getPathName';
import decodeHtml from 'utilities/decodeHtml';

const utagLinkSelectItem = ({
	sku, name, price, skuInputType, primaryCategory, productNewItemDate
}) => {

	const payload = {
		event_name: 'select_item',
		item_list_name: [ getPathName() ],
		product_name: sku && name ? [ decodeHtml(name) ] : [],
		product_sku: sku ? [ sku ] : [],
		product_price: price ? [ price ] : [],
		product_grouping: [],
		sku_input_type: skuInputType ? [skuInputType] : [],
		product_category : [primaryCategory] ? [primaryCategory] : [],
		product_new_item_date: productNewItemDate ? [productNewItemDate] : []
	};

	if (window.utag && window.utag_data) {

		// populate GA4 item_category5 (product grouping)
		if (window.utag_data.product_grouping && window.utag_data.product_grouping.length > 0) {

			const [ productGrouping ] = window.utag_data.product_grouping;
			payload.product_grouping.push(productGrouping);

		}

		window.utag.link(payload);

	}

};

export default utagLinkSelectItem;
