import { debounce } from 'lodash-es';

import decodeHtml from 'utilities/decodeHtml';

import getPathName from './getPathName';

const debounceTime = 500;

const STATUS_PENDING = 'pending';
const STATUS_SENDING = 'sending';
const STATUS_TRACKED = 'tracked';
const STATUS_FAILED = 'failed';

// Compared with sendProductImpressions, widget_name and vendor_widget accumulate nulls, original is just empty array

const eventStructure = {
	product_impression_sku: '',
	product_impression_name: '',
	product_impression_price: '',
	product_impression_list: '',
	product_impression_position: '',
	product_impression_category: '',

	product_primary_category: '',
	product_primary_color: '',
	product_primary_finish: '',
	product_primary_type: '',
	product_primary_style: '',

	sku_input_type: '',
	product_islpproduct: '',
	product_islpnewproduct: '',
	is_special_value: '',
	/* Start: These are from utag data at [0] and identical, need to copy for each? What could be different? */
	filter_id: '',
	test_id: '',
	testcomposition_id: '',
	mm_id: '',
	formula_id: '',
	pin_id: '',
	test_start_date: '',
	/* End: These are from utag data at [0] and identical, need to copy for each? What could be different? */

	widget_name: '',
	vendor_widget: ''
};
const eventStructureKeys = Object.keys(eventStructure);

let uniqueId = -1;
function autoId () {

	uniqueId += 1;
	return uniqueId;

}

class ProductImpressionsReporter {

	constructor ({ list_type, product_grouping }) {

		// eslint-disable-line camelcase

		this.pageViewId = autoId();
		this.pageViewHref = document.location.href;
		this.list_type = list_type || null; // eslint-disable-line camelcase
		this.product_grouping = product_grouping; // eslint-disable-line camelcase
		this.records = {};

		this.debouncedSend = debounce(() => {

			// Does the browser support requestIdleCallback
			if ('requestIdleCallback' in window) {

				return window.requestIdleCallback(this.send);

			}
			this.send();

		}, debounceTime);

		this.track = this.track.bind(this);
		this.send = this.send.bind(this);
		this.getRecordsByStatus = this.getRecordsByStatus.bind(this);

	}

	// Register page navigation (after navigation, such as in React Router)
	registerPageNavigation () {

		// We have to send because event_label relies on document.location.href which changes
		this.send();

		this.pageViewId = autoId();
		this.pageViewHref = document.location.href;

	}

	flushOldPageRecords () {

		const currentPageViewId = this.pageViewId;
		// Avoid deleting pending records from previous page view by calling send
		this.send().then(() => {

			// All records have been sent, safe to cleanup
			const matchCurrentPageViewId = new RegExp(`^${currentPageViewId}:`);
			const oldRecords = Object.keys(this.records).filter((record) => !matchCurrentPageViewId.test(record));
			oldRecords.forEach((recordName) => {

				delete this.records[recordName];

			});

		});

	}

	getPageViewId () {

		return this.pageViewId;

	}

	getProductImpressionDataFromElement (element) {

		const { dataset } = element;
		const id = [ this.pageViewId, dataset.sku ].join(':');

		const data = {
			id,
			...eventStructure,
			product_impression_sku: dataset.sku || '',
			product_impression_name: dataset.name || element.title || element.alt || '',
			product_impression_price: dataset.price || '',
			product_impression_list: getPathName(),
			product_impression_position: dataset.position || '',
			product_impression_category: dataset.primaryCategory || '',
			product_primary_category: dataset.primaryCategory || '',
			product_primary_color: dataset.primaryColor || '',
			product_primary_finish: dataset.primaryFinish || '',
			product_primary_type: dataset.primaryType || '',
			product_primary_style: dataset.primaryStyle || '',
			product_islpproduct: dataset.productIsLpproduct || '',
			product_islpnewproduct: dataset.productIsLpNewProduct || '',
			filter_id: window.utag_data.filter_id[0] || '',
			test_id: window.utag_data.test_id[0] || '',
			testcomposition_id: window.utag_data.testcomposition_id[0] || '',
			mm_id: window.utag_data.mm_id[0] || '',
			formula_id: window.utag_data.formula_id[0] || '',
			pin_id: window.utag_data.pin_id[0] || '',
			test_start_date: window.utag_data.test_start_date[0] || '',
			widget_name: dataset.widgetName || '',
			vendor_widget: dataset.vendorWidget || '',
			sku_input_type: dataset.skuInputType || '',
			is_special_value: dataset.isSpecialValue || ''
		};

		return data;

	}

	// Called in the callback when the element qualifies
	track (data, options = { immediate: false }) {

		const { id } = data;

		if (this.records[id]) {

			console.warn(`Product Impressions: track call ignored for id ${id}; Already fired once.`);
			return Promise.resolve();

		}

		// Deferred promise
		let resolvePromise;
		let rejectPromise;
		const promise = new Promise((resolve, reject) => {

			resolvePromise = resolve;
			rejectPromise = reject;

		});

		this.records[id] = {
			data,
			status: STATUS_PENDING,
			promise,
			resolve: resolvePromise,
			reject: rejectPromise
		};

		if (options.immediate) {

			this.send();

		} else {

			this.debouncedSend();

		}

		return promise;

	}

	static makeEventPayload (record) {

		// Destructure out properties that are not part of the payload, or need special handling
		const { id, product_impression_name, ...properties } = record.data; // eslint-disable-line camelcase
		const payload = {
			...eventStructure,
			...properties,
			product_impression_name: decodeHtml(product_impression_name)
		};
		return payload;

	}

	// reduce each event object property into ordinal arrays for the payload
	makeRequestPayload (records) {

		const payload = {
			event_category: 'ecommerce',
			event_action: 'product impression',
			event_label: this.pageViewHref,
			event_noninteraction: 'true',
			gua_non_interaction: true,
			list_type: this.list_type,
			product_grouping: this.product_grouping,

			product_impression_sku: [],
			product_impression_name: [],
			product_impression_price: [],
			product_impression_list: [],
			product_impression_position: [],
			product_impression_category: [],

			product_primary_category: [],
			product_primary_color: [],
			product_primary_finish: [],
			product_primary_type: [],
			product_primary_style: [],

			sku_input_type: [],
			product_islpproduct: [],
			product_islpnewproduct: [],
			filter_id: [],
			test_id: [],
			testcomposition_id: [],
			mm_id: [],
			formula_id: [],
			pin_id: [],
			test_start_date: [],
			widget_name: [],
			vendor_widget: [],
			is_special_value: []
		};

		records.reduce((memo, record) => {

			// Only push properties declared for the event structure
			eventStructureKeys.forEach((key) => {

				memo[key].push(record[key]);

			});

			return memo;

		}, payload);

		return payload;

	}

	send () {

		const records = this.getRecordsByStatus(STATUS_PENDING);

		if (records.length === 0 || !window.utag) {

			return Promise.resolve({});

		}

		// Get payload data
		const events = records.map(ProductImpressionsReporter.makeEventPayload);
		const data = this.makeRequestPayload(events);

		// Update status of records to track as in progress
		ProductImpressionsReporter.updateRecordsStatus(records, STATUS_SENDING);

		const request = new Promise((resolve) => {

			const wasSuccessful = window.utag.link(data, () => resolve('success'));
			if (wasSuccessful) {

				resolve('success');

			}

			// Tracking is allowed 500ms for successful resolution,
			// then we redirect to the cart regardless.
			setTimeout(() => resolve('success'), 500);

		});

		request
			.then(() => {

				// Update status of records to track as tracked
				ProductImpressionsReporter.updateRecordsStatus(records, STATUS_TRACKED);

			})
			.catch(() => {

				ProductImpressionsReporter.updateRecordsStatus(records, STATUS_FAILED);

			});

		return request;

	}

	getRecordById (id) {

		return this.records[id];

	}

	getRecordsByStatus (status) {

		return Object.keys(this.records).reduce((memo, item) => {

			const record = this.records[item];
			if (record.status === status) {

				memo.push(record);

			}
			return memo;

		}, []);

	}

	static updateRecordsStatus (records, status) {

		records.forEach((record) => {

			record.status = status; // eslint-disable-line no-param-reassign

			if (status === STATUS_TRACKED) {

				record.resolve();

			} else if (status === STATUS_FAILED) {

				record.reject();

			}

		});

	}

}

export default ProductImpressionsReporter;
